import { createWebHistory, createRouter } from 'vue-router';
import { authGuard } from './auth-guard';

const routes = [
	{ path: '/:catchAll(.*)', redirect: { name: 'Login' } },
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/components/layout/AuthLayout.vue')
	}, {
		path: '/admin',
		name: 'AppLayout',
		beforeEnter: authGuard,
		component: () => import('@/components/layout/AppLayout.vue'),
		children: [
			{
				path: '/dashboard',
				name: 'Dashboard',
				component: () => import('@/components/pages/Dashboard.vue')
			},{
				path: '/profile',
				name: 'Profile',
				component: () => import('@/components/pages/UpdateProfile.vue')
			},{
				path: '/user',
				name: 'User',
				component: () => import('@/components/pages/User.vue')
			},{
				path: '/aboutus',
				name: 'AboutUs',
				component: () => import('@/components/pages/AboutUs.vue')
			},{
				path: '/token',
				name: 'Token',
				component: () => import('@/components/pages/Token.vue')
			},{
				path: '/aboutus',
				name: 'Aboutus',
				component: () => import('@/components/pages/AboutUs.vue')
			},{
				path: '/logout',
				name: 'Logout',
				component: () => import('@/components/pages/Logout.vue')
			},{
				path: '/role',
				name: 'Role',
				component: () => import('@/components/pages/Role.vue')
			},{
				path: '/subscription',
				name: 'Subscription',
				component: () => import('@/components/pages/Subscription.vue')
			}
		]
	}
];

const router = createRouter({
	history: createWebHistory(),
	hash: false,
	routes,
});

export default router;